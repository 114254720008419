.block {
  background-color: var(--color-white);
  position: absolute;
  text-align: center;
  width: 100%;

  &:not(:focus-visible) {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0 !important;
    visibility: visible;
    white-space: nowrap;
    width: 1px;
  }
}
